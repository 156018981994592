<template>
  <div>
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-md-4 d-flex justify-content-between align-items-center">
                <div class="header-title me-5">
                  <h4 class="card-title">Рейсы</h4>
                </div>
                <button
                  type="button"
                  class="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#new-flights"
                >
                  Добавить
                </button>
              </div>
            </div>
          </div>

          <div class="card-body px-0">
            <div class="table-responsive" style="min-height: 250px;">
              <table id="user-list-table" class="table overflow-auto" role="grid" data-toggle="data-table">
                <thead>
                <tr class="ligth">
                  <th v-for="(header, index) in tableHeaders" :key="index">{{ header }}</th>
                </tr>
                </thead>
                <tbody v-if="flights?.length">
                <tr v-for="(flight, index) in flights" :key="index">
                  <td>{{ flight.flight_number }}</td>
                  <td>{{ flight.departure_country.name.ru }}</td>
                  <td>{{ flight.arrival_country.name.ru }}</td>
                  <td>{{ flight.departure_time }}</td>
                  <td>{{ flight.arrival_time }}</td>
                  <td>
                    <div class="dropdown">
                      <a class="btn btn-primary dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                         data-bs-toggle="dropdown" aria-expanded="false">
                        Опции
                      </a>
                      <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <li>
                          <a class="dropdown-item" href="#" data-bs-toggle="modal"
                             data-bs-target="#edit-flight" @click.prevent="selectFlight(flight.id)">Редактировать</a>
                        </li>
                        <li>
                          <button class="dropdown-item btn-link text-danger" @click="deleteFlight(flight.id)">
                            Удалить
                          </button>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div v-if="flights?.pagination?.total > 10" class="card-footer d-flex justify-content-center">
            <div class="bd-content">
              <CustomPagination
                :total-pages="flights?.pagination?.total_pages || 1"
                :total="flights?.pagination?.total || 1"
                :per-page="10"
                :current-page="currentPage"
                @pagechanged="onPageChange"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- create flight Modal -->
    <modal
      mainClass="fade"
      :tabindex="-1"
      id="new-flights"
      ariaLabelled="addModalLabel"
      :ariaHidden="true"
      dialog-class="modal-lg modal-dialog-scrollable"
    >
      <model-header :dismissable="true"></model-header>

      <model-body class="px-5">
        <h1 class="text-center">Новый рейс</h1>

        <Form v-slot="{ meta }" class="row mt-4" @submit="addFlights">
          <div class="col-md-4">
            <label class="form-label">№ рейса</label>
            <Field
              type="text"
              class="form-control"
              rules="required"
              name="flight_number"
            />
            <ErrorMessage name="flight_number" class="text-danger" />
          </div>

          <div class="col-md-4">
            <label for="departure_country" class="form-label">Откуда</label>
            <Field id="departure_country" as="select" class="form-select" rules="required" name="departure_country">
              <option class="fst-italic" selected="" disabled="" value="">Выберите страну</option>
              <option v-for="country in countries" :key="country.id" :value="country.id">{{ country.name.ru }}</option>
            </Field>
            <ErrorMessage name="departure_country" class="text-danger" />
          </div>

          <div class="col-md-4">
            <label for="arrival_country" class="form-label">Куда</label>
            <Field id="arrival_country" as="select" class="form-select" rules="required" name="arrival_country">
              <option class="fst-italic" selected="" disabled="" value="">Выберите страну</option>
              <option v-for="country in countries" :key="country.id" :value="country.id">{{ country.name.ru }}</option>
            </Field>
            <ErrorMessage name="arrival_country" class="text-danger" />
          </div>

          <div class="col-md-6 mt-4">
            <label class="form-label">Вылет</label>
            <Field
              type="datetime-local"
              class="form-control"
              rules="required"
              name="departure_time"
            />
            <ErrorMessage name="departure_time" class="text-danger" />
          </div>

          <div class="col-md-6 mt-4">
            <label class="form-label">Прибытие</label>
            <Field
              type="datetime-local"
              class="form-control"
              rules="required"
              name="arrival_time"
            />
            <ErrorMessage name="arrival_time" class="text-danger" />
          </div>

          <div class="d-flex justify-content-center mt-4">
            <button type="submit" class="btn btn-primary" :disabled="!(meta.valid && meta.dirty)">Сохранить</button>
          </div>
        </Form>

        <br><br>
      </model-body>
    </modal>

    <!-- flights edit Modal -->
    <modal
      mainClass="fade"
      :tabindex="-1"
      id="edit-flight"
      ariaLabelled="addModalLabel"
      :ariaHidden="true"
      dialog-class="modal-xl modal-dialog-scrollable text-black"
    >
      <model-header :dismissable="true"></model-header>

      <model-body class="px-5">

        <div v-if="!flightData">Загрузка....</div>

        <div v-else>
          <h1 class="text-center">Рейс #{{ flightData.flight_number }}</h1>

          <Form v-slot="{ meta }" class="row mt-4" @submit="editFlight">
            <div class="col-md-4">
              <label class="form-label">№ рейса</label>
              <Field
                v-model="flightData.flight_number"
                type="text"
                class="form-control"
                rules="required"
                name="flight_number"
              />
              <ErrorMessage name="flight_number" class="text-danger" />
            </div>

            <div class="col-md-4">
              <label for="departure_country" class="form-label">Откуда</label>
              <Field v-model="flightData.departure_country.id" id="departure_country" as="select" class="form-select" rules="required" name="departure_country">
                <option class="fst-italic" selected="" disabled="" value="">Выберите страну</option>
                <option v-for="country in countries" :key="country.id" :value="country.id">{{ country.name.ru }}</option>
              </Field>
              <ErrorMessage name="departure_country" class="text-danger" />
            </div>

            <div class="col-md-4">
              <label for="arrival_country" class="form-label">Куда</label>
              <Field v-model="flightData.arrival_country.id" id="arrival_country" as="select" class="form-select" rules="required" name="arrival_country">
                <option class="fst-italic" selected="" disabled="" value="">Выберите страну</option>
                <option v-for="country in countries" :key="country.id" :value="country.id">{{ country.name.ru }}</option>
              </Field>
              <ErrorMessage name="arrival_country" class="text-danger" />
            </div>

            <div class="col-md-6 mt-4">
              <label class="form-label">Вылет</label>
              <Field
                v-model="flightData.departure_time"
                type="datetime-local"
                class="form-control"
                rules="required"
                name="departure_time"
              />
              <ErrorMessage name="departure_time" class="text-danger" />
            </div>

            <div class="col-md-6 mt-4">
              <label class="form-label">Прибытие</label>
              <Field
                v-model="flightData.arrival_time"
                type="datetime-local"
                class="form-control"
                rules="required"
                name="arrival_time"
              />
              <ErrorMessage name="arrival_time" class="text-danger" />
            </div>

            <div class="d-flex justify-content-center mt-4">
              <button type="submit" class="btn btn-primary" :disabled="!(meta.valid && meta.dirty)">Сохранить</button>
            </div>
          </Form>
        </div>
      </model-body>
    </modal>
  </div>
</template>

<script>
import { ErrorMessage, Field, Form } from 'vee-validate'
import modal from '@/components/bootstrap/modal/modal'
import modelHeader from '@/components/bootstrap/modal/model-header'
import modelBody from '@/components/bootstrap/modal/model-body'
import CustomPagination from '@/components/custom/pagination/CustomPagination'

import { createFlight, getFlights, updateFlight, removeFlight } from '@/services/flights.service'
import { getCountries } from '@/services/countries.service'

export default {
  name: 'FligthsIndex',
  data: () => ({
    currentPage: 1,
    flightData: null,
    flights: null,
    countries: null,
    isLoading: {
      order: false
    },
    tableHeaders: [
      '№ рейса',
      'Откуда',
      'Куда',
      'Вылет',
      'Прибытие',
      'Действия'
    ]
  }),
  components: {
    CustomPagination,
    modal,
    modelBody,
    modelHeader,
    Form,
    Field,
    ErrorMessage
  },
  methods: {
    onPageChange (page) {
      this.currentPage = page
    },
    selectFlight (id) {
      this.flightData = this.flights.find((flight) => flight.id === id)
    },
    async fetchCountries () {
      const { data: countriesResponse } = await getCountries()
      this.countries = countriesResponse.data
    },
    async addFlights (values) {
      const formData = new FormData()

      Object.entries(values).forEach(([name, value]) => {
        formData.append(name, String(value))
      })

      await createFlight(formData)

      window.location.reload()
    },
    async fetchFlight () {
      const { data: flightsResponse } = await getFlights({
        paginate: 10,
        page: this.currentPage
      })

      this.flights = flightsResponse.data
    },
    async editFlight (values) {
      const formData = new FormData()

      formData.append('id', this.flightData.id)
      Object.entries(values).forEach(([name, value]) => {
        formData.append(name, String(value))
      })

      await updateFlight(formData)

      window.location.reload()
    },
    async deleteFlight (id) {
      if (confirm('Удалить ?')) {
        await removeFlight(id)
        await this.fetchFlight()
      }
    }
  },
  async created () {
    await this.fetchFlight()
    await this.fetchCountries()
  }
}
</script>
